import linkContext from "./linkContext";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

const LinkState = (props) => {
  let host = "http://localhost:3000/api";
  if (document.location.href.includes("localhost")) {
    host = "http://localhost:3000/api";
  } else {
    host = "api";
  }

  const publicUrl = "https://www.cbxtree.in";

  const linksInitial = [];
  const [links, setLinks] = useState(linksInitial);
  const [clicks, setClicks] = useState([]);
  const [settings, setSettings] = useState([]);
  const [user, setUser] = useState([]);
  const [viewlinks, setViewlinks] = useState([]);
  const [viewsetting, setViewsetting] = useState(null);
  const navigate = useNavigate();
  const [previewUrl, setPreviewUrl] = useState("");
  const [allLinks, setAllLinks] = useState([]);
  const [linkCount, setLinkCount] = useState(0);
  const [settingsCount, setSettingsCount] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [userLogs, setUserLogs] = useState({});
  const [linksLogs, setlinksLogs] = useState({});
  const [shareSettingData, setShareSettingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldHideIcons, setShouldHideIcons] = useState(false);
  const [newFeaturesData, setNewFeaturesData] = useState([]);
  const [badgeCount, setBadgeCount] = useState(0);

  const shareUrl = Cookies.get("cbxtree");

  let userInfo;
  const userInfoString = Cookies.get("userInfo");
  userInfo = userInfoString ? JSON.parse(userInfoString) : null;

  useEffect(() => {
    if (userInfo) {
      let newPreviewUrl = "";
      if (userInfo?.domain) {
        newPreviewUrl =
          userInfo?.userid?.length > 0
            ? `${userInfo.domain}/${shareUrl}`
            : `${userInfo.domain}/${shareUrl}`;
      } else {
        newPreviewUrl =
          userInfo?.userid?.length > 0
            ? `${publicUrl}/${shareUrl}`
            : `${publicUrl}/${shareUrl}`;
      }
      setPreviewUrl(newPreviewUrl);
    } else {
      let newPreviewUrl = "";
      if (user?.domain) {
        newPreviewUrl =
          user?.userid?.length > 0
            ? `${user.domain}/${user?.userid[user.userid.length - 1]}`
            : `${user.domain}/${user?._id}`;
      } else {
        newPreviewUrl =
          user?.userid?.length > 0
            ? `${publicUrl}/${user?.userid[user.userid.length - 1]}`
            : `${publicUrl}/${user?._id}`;
      }
      setPreviewUrl(newPreviewUrl);
    }
  }, [user, publicUrl, userInfo]);

  // useEffect(() => {
  //   let newPreviewUrl = "";
  //   if (user?.domain) {
  //     newPreviewUrl =
  //       user?.userid?.length > 0
  //         ? `${user.domain}/${user?.userid[user.userid.length - 1]}`
  //         : `${user.domain}/${user?._id}`;
  //   } else {
  //     newPreviewUrl =
  //       user?.userid?.length > 0
  //         ? `${publicUrl}/${user?.userid[user.userid.length - 1]}`
  //         : `${publicUrl}/${user?._id}`;
  //   }
  //   setPreviewUrl(newPreviewUrl);
  // }, [user, publicUrl]);

  useEffect(() => {
    const storedBadgeCount = localStorage.getItem("badgeCount");
    if (storedBadgeCount !== null) {
      setBadgeCount(parseInt(storedBadgeCount, 10));
    }
  }, []);

  const extractUserId = (url) => {
    const regex = /^https?:\/\/[^\/]+\/(.+)$/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return "";
  };

  const userName = extractUserId(previewUrl);

  const UserData = async () => {
    const response = await fetch(`${host}/auth/getalluser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    setAllUsers(json.clients);
  };

  // Get userLogs
  const getUserLogs = async () => {
    const response = await fetch(`${host}/auth/getuserlogs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    setUserLogs(json);
  };

  // Get linksLogs
  const getLinksLogs = async () => {
    const response = await fetch(`${host}/links/getlinkslogs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    setlinksLogs(json);
  };

  // Get all LinkseditLink
  const getLinks = async () => {
    const id = userInfo && userInfo._id;
    try {
      const response = await fetch(`${host}/links/fetchlinks/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      setLinks(json);
    } catch (error) {
      console.log(error);
    }
  };

  // Get all links
  const getAllLinks = async () => {
    try {
      const response = await fetch(`${host}/links/fetchAllLinks`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      setAllLinks(json);
    } catch (error) {
      console.log(error);
    }
  };

  // Get links count
  const getLinksCount = async () => {
    try {
      const response = await fetch(`${host}/links/fetchLinksCount`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      setLinkCount(json?.count);
    } catch (error) {
      console.log(error);
    }
  };

  function transformLookerLink(originalLink) {
    const match = originalLink.split("/");
    let value = "";
    for (let i of match) {
      if (i.length === 36) {
        value = i;
      }
    }
    return `https://lookerstudio.google.com/embed/reporting/${value}`;
  }

  // Add a Links
  const addLink = async (linkData) => {
    const id = userInfo && userInfo._id;
    // Find the maximum order value
    const maxOrder = links.reduce(
      (max, link) => (link.order > max ? link.order : max),
      0
    );
    // Assign a new order to the new link
    let newLink = maxOrder + 1;
    // Update the state with the new link
    linkData.order = newLink;

    const { description, link, linkType, instantLink, order, userid } =
      linkData;

    if (link && link.includes("https://lookerstudio.google.com")) {
      const transformedLink = transformLookerLink(link);
      if (transformedLink) {
        linkData.link = transformedLink;
      }
    }

    const response = await fetch(`${host}/links/addlink`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        description,
        link: linkData.link,
        linkType,
        instantLink,
        order,
        userid,
        id,
      }),
    });
    const linkData1 = await response.json();
    getLinks();
    // setLinks(links.concat(linkData1));
  };

  const updateOrder = async (items) => {
    try {
      const response = await fetch(`${host}/links/updateOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ newOrder: items }),
      });
      const json = await response.json();
      getLinks();
      // setLinks(json.linkData);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  // Delete a Link
  const deleteLink = async (id) => {
    const shareUserId = userInfo && userInfo._id;
    const response = await fetch(`${host}/links/deletelink/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        shareUserId,
      }),
    });
    await response.json();
    const newLink = links.filter((link) => link._id !== id);
    setLinks(newLink);
    getLinks();
  };
  // Edit a Link
  const editLink = async (id, description, link, linkType, instantLink) => {
    const shareUserId = userInfo && userInfo._id;
    const response = await fetch(`${host}/links/updatelink/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        description,
        link,
        linkType,
        instantLink,
        shareUserId,
      }),
    });
    await response.json();

    let newLinks = JSON.parse(JSON.stringify(links));
    // Logic to edit in client
    for (let index = 0; index < newLinks.length; index++) {
      const element = newLinks[index];
      if (element._id === id) {
        newLinks[index].description = description;
        newLinks[index].link = link;
        newLinks[index].linkType = linkType;
        newLinks[index].instantLink = instantLink;
        break;
      }
    }
    setLinks(newLinks);
  };

  const addAnimation = async (e, id) => {
    const value = e.target.value;
    const animationId = id;

    // API Call
    const response = await fetch(`${host}/links/animation`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ _id: animationId, animation: value }),
    });
    const json = await response.json();
    // setLinks(json.links);
    getLinks();
  };

  const addEndDate = async (id, date) => {
    const shareUserId = userInfo && userInfo._id;
    try {
      const response = await fetch(`${host}/links/updateEndDate/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ endDate: date, shareUserId }),
      });
      const json = await response.json();
      getLinks();
      // setLinks(json.links);
    } catch (e) {
      console.log();
    }
  };

  const onChangethumbnail = async (id, thumbnail) => {
    // API Call
    const response = await fetch(`${host}/links/thumbnail`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ _id: id, thumbnail: thumbnail }),
    });
    await response.json();
    getLinks();
  };

  // Enable or disable link
  const updatelinkED = async (_id) => {
    const response = await fetch(`${host}/links/isdisable`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ _id: _id }),
    });
    const json = await response.json();
    getLinks();
    // setLinks(json.links);
  };

  // updateLinkSecureStatus
  const updateLinkSecureStatus = async (_id) => {
    try {
      const response = await fetch(`${host}/links/issecure`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ _id: _id }),
      });
      const json = await response.json();
      await getLinks();
      await getSettings();
    } catch (error) {
      console.log(error);
    }
  };

  // Authantication context
  // Signup
  // const handleSubmit = async (e, credentials, props) => {
  //   e.preventDefault();

  //   const { name, email, password, cpassword } = credentials;
  //   if (password !== cpassword) {
  //     props.showAlert(
  //       "Invalid Credentials, Password doestn't match with Confirm Password",
  //       "danger"
  //     );
  //     return;
  //   }
  //   const response = await fetch(`${host}/auth/createuser`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ name, email, password }),
  //   });
  //   const json = await response.json();

  //   if (json.success) {
  //     addSettings(json.authtoken);
  //     navigate("/login");
  //     props.showAlert("Account Created Successfully", "success");
  //   } else {
  //     props.showAlert("Invalid Credentials", "danger");
  //   }
  // };

  const handleSubmit = async (e, credentials, props) => {
    e.preventDefault();

    const { name, email, password, cpassword } = credentials;

    // Check if password matches
    if (password !== cpassword) {
      props.showAlert(
        "Invalid Credentials, Password doesn't match with Confirm Password",
        "danger"
      );
      return;
    }

    try {
      const response = await fetch(`${host}/auth/createuser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, password }),
      });

      const json = await response.json();

      // Check if the response is successful
      if (json.success) {
        addSettings(json.authtoken);
        navigate("/login");
        props.showAlert("Account Created Successfully", "success");
      } else if (
        json.error &&
        json.error.includes("Sorry a user with this email already exists")
      ) {
        props.showAlert("User already exists, please log in.", "danger");
      } else {
        props.showAlert("Invalid Credentials", "danger");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      props.showAlert(
        "Something went wrong. Please try again later.",
        "danger"
      );
    }
  };

  // Settings context
  // Add Settings
  const addSettings = async (token) => {
    const logo = "",
      header = "",
      description = "",
      backgroundImage = "",
      background = "",
      socialLinks = [];
    const response = await fetch(`${host}/settings/addsettings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify({
        logo,
        header,
        description,
        backgroundImage,
        background,
        socialLinks,
      }),
    });
    await response.json();
  };

  // Get all Settings
  const getSettings = async () => {
    const id = userInfo && userInfo._id;
    try {
      const response = await fetch(`${host}/settings/fetchsettings/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      setSettings(json);
    } catch (error) {
      console.log(error);
    }
  };

  // Get settings count
  const getSettingsCount = async () => {
    try {
      const response = await fetch(`${host}/settings/fetchSettingsCount`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      setSettingsCount(json?.count);
    } catch (error) {
      console.log(error);
    }
  };

  const userViewDetails = async (viewId) => {
    try {
      const response = await fetch(
        `${host}/settings/fetch-view-settings/${viewId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      setSettings(json);
    } catch (error) {
      console.log(error);
    }
  };

  // Update logo
  const updateLogo = async (logo) => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/updatelogo`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ logo: logo, userName, id }),
    });
    const json = await response.json();
    // setSettings(json);
    getSettings();
  };

  // Update Background Image
  const updateBgImage = async (image) => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/updatebgimage`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ backgroundImage: image, userName, id }),
    });
    const json = await response.json();
    // setSettings(json);
    getSettings();
  };

  // Update Background in Linear Gradiant
  const updateLgBackground = async (bg) => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/updatelgbackground`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ background: bg, userName, id }),
    });
    const json = await response.json();
    getSettings();
    // setSettings(json);
  };

  // Update Social Link
  const updateSocialLinks = async (link, props) => {
    // API Call
    const response = await fetch(`${host}/settings/updatesociallink`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ socialLinks: link, userName }),
    });
    const json = await response.json();
    console.log("json", json.settings, json);
    // setSettings(json.settings);
    getSettings();
    props.showAlert(json.message, "success");
  };

  const deleteSL = async (id, props) => {
    // API Call
    const response = await fetch(`${host}/settings/deleteSL/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ userName }),
    });
    const json = await response.json();
    // setSettings(json.settings);
    getSettings();
    props.showAlert(json.message, "success");
  };

  // Update Header
  const addHeader = async (header) => {
    const id = userInfo && userInfo._id;
    try {
      const response = await fetch(`${host}/settings/updateheader`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ header: header, userName, id }),
      });
      const json = await response.json();
      getSettings();
    } catch (error) {
      console.log(error);
    }
  };

  // Update Header
  const addCompanyName = async (companyName) => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/updatecompanyname`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ companyName: companyName, userName, id }),
    });
    const json = await response.json();
    getSettings();
    // setSettings(json);
  };

  // Update Left Footer
  const updateleftfooter = async (link) => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/leftfooter`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ leftFooter: link, userName, id }),
    });
    const json = await response.json();
    // setSettings(json);
    getSettings();
  };

  // Update Right Footer
  const updaterightfooter = async (link) => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/rightfooter`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ rightFooter: link, userName, id }),
    });
    const json = await response.json();
    getSettings();
    // setSettings(json);
  };

  // Update Header
  const addSheetLink = async (sheetLink) => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/updatesheetlink`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ sheetLink, userName, id }),
    });
    const json = await response.json();
    console.log(json);
    getSettings();
    // setSettings(json);
  };

  // Update Description
  const addDescription = async (description) => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/updatedescription`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ description: description, userName, id }),
    });
    const json = await response.json();
    getSettings();
    // setSettings(json);
  };

  // Load Settings
  // const loadSettings = async (id, domainName) => {
  //   try {
  //     const response = await fetch(
  //       `${host}/settings/loadsetting/${id}?domainName=${domainName}`,
  //       // `${host}/settings/loadsetting/${id}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     const json = await response.json();
  //     setViewsetting(json);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  const loadSettings = async (id, domainName) => {
    try {
      const response = await axios.get(`${host}/settings/loadsetting/${id}`, {
        params: { domainName },
        headers: {
          "Content-Type": "application/json",
        },
      });
      setViewsetting(response.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Load Links
  // const loadLinks = async (userid, domainName) => {
  //   try {
  //     const response = await fetch(
  //       `${host}/links/loadlinks/${userid}?domainName=${domainName}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     const json = await response.json();
  //     setViewlinks(json);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const loadLinks = async (userid, domainName) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${host}/links/loadlinks/${userid}`, {
        params: { domainName },
        headers: {
          "Content-Type": "application/json",
        },
      });
      setViewlinks(response.data);
    } catch (error) {
      console.error("Error loading links:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Load user
  const getUserid = async (id, domainName) => {
    try {
      const url = new URL(`https://www.cbxtree.in/api/auth/getUserid/${id}`);
      // const url = new URL(`${host}/auth/getUserid/${id}`);

      url.searchParams.append("domainName", domainName);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      // loadLinks(json.user._id);
      // loadSettings(json.user._id);
      // let data = viewlinks?.filter((item) => item.user === json.user._id);
      // setViewlinks(data);
    } catch (error) {
      setViewsetting({ success: false });
      console.log("errro", error);
    }
  };

  // set footer ture false
  const handleFooter = async () => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/handlefooter`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ userName, id }),
    });
    const json = await response.json();
    getSettings();
    // setSettings(json);
  };

  // set Search ture false
  const handleSearch = async () => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/handlesearch`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ userName, id }),
    });
    const json = await response.json();
    getSettings();
    // setSettings(json);
  };

  const addClickInfo = async (data) => {
    const response = await fetch(`${host}/links/addClicks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    await response.json();
  };

  // Fetch clicks
  const fetchclicks = async () => {
    try {
      const response = await axios.get(`${host}/links/fetchclicks`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setClicks(response.data);
    } catch (error) {
      console.error("Error fetching clicks data:", error.message);
    }
  };

  // Update header setting
  const updateHeaderSetting = async (
    editedFontSize,
    editedFontColor,
    editedFontFamily
  ) => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/updateheaderStyle`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        fontSize: editedFontSize,
        fontColor: editedFontColor,
        fontFamily: editedFontFamily,
        userName,
        id,
      }),
    });
    const json = await response.json();
    getSettings();
    // setSettings(json );
  };

  // Update description setting
  const updateDescriptionSetting = async (
    editDescColor,
    editDescSize,
    editDescFontFamily
  ) => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/updatedescriptionStyle`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        size: editDescSize,
        color: editDescColor,
        family: editDescFontFamily,
        userName,
        id,
      }),
    });
    const json = await response.json();
    getSettings();
    // setSettings(json);
  };

  // Update left footer setting
  const updatedleftfooterStyle = async (
    editLeftFooterColor,
    editLeftFooterSize,
    editLeftFooterFamily,
    editLeftFooterBackground
  ) => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/updatedleftfooterStyle`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        size: editLeftFooterSize,
        color: editLeftFooterColor,
        family: editLeftFooterFamily,
        background: editLeftFooterBackground,
        userName,
        id,
      }),
    });
    const json = await response.json();
    getSettings();
    // setSettings(json);
  };

  // Update right footer setting
  const updatedrightfooterStyle = async (
    editRightFooterColor,
    editRightFooterSize,
    editRightFooterFamily,
    editRightFooterBackground
  ) => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/updatedrightfooterStyle`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        size: editRightFooterSize,
        color: editRightFooterColor,
        family: editRightFooterFamily,
        background: editRightFooterBackground,
        userName,
        id,
      }),
    });
    const json = await response.json();
    getSettings();
    // setSettings(json);
  };

  // Update pagination setting
  const updatedpaginationStyle = async (
    editPaginationButtonColor,
    editPaginationButtonSize,
    editPaginationButtonFamily,
    editPaginationButtonBackground
  ) => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/updatedpaginationStyle`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        size: editPaginationButtonSize,
        color: editPaginationButtonColor,
        family: editPaginationButtonFamily,
        background: editPaginationButtonBackground,
        userName,
        id,
      }),
    });
    const json = await response.json();
    getSettings();
    // setSettings(json);
  };

  // Update links setting
  const updatedLinksStyle = async (
    editPaginationButtonColor,
    editPaginationButtonSize,
    editPaginationButtonFamily,
    editPaginationButtonBackground,
    editLinksBorderRadius
  ) => {
    const id = userInfo && userInfo._id;
    const response = await fetch(`${host}/settings/updatedLinksStyles`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        size: editPaginationButtonSize,
        color: editPaginationButtonColor,
        family: editPaginationButtonFamily,
        background: editPaginationButtonBackground,
        borderRadius: editLinksBorderRadius,
        userName,
        id,
      }),
    });
    const json = await response.json();
    getSettings();
    // setSettings(json);
  };

  // Add domain api
  const handleAddDomain = async (domain, props) => {
    if (domain === "https://www.cbxtree.in" || domain === "cbxtree.in") {
      return;
    }

    if (domain) {
      // Check if the domain starts with a valid protocol
      const hasValidProtocol =
        domain.startsWith("https://") || domain.startsWith("http://");
      let cleanDomain = domain;
      if (hasValidProtocol) {
        cleanDomain = domain.replace(/^https?:\/\//, "");
      }

      const domainParts = cleanDomain.split(".");
      const hasSubdomain = domainParts.length > 2;

      // Construct the final domain
      if (!hasValidProtocol) {
        if (hasSubdomain) {
          // If it has a subdomain, add https://
          domain = `https://${cleanDomain}`;
        } else {
          // Add https://www. if it's a plain second-level domain
          domain = `https://www.${cleanDomain}`;
        }
      } else {
        // If it starts with a valid protocol but is missing www. and is a plain domain
        if (!cleanDomain.startsWith("www.") && !hasSubdomain) {
          domain = `https://www.${cleanDomain}`;
        } else {
          domain = `https://${cleanDomain}`;
        }
      }
    }

    const response = await fetch(`${host}/auth/adddomain`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        domain: domain,
      }),
    });
    const json = await response.json();
    setUser(json.user);
  };

  // Handle update userName
  const handleAddUseId = async (userid, props) => {
    const response = await fetch(`${host}/auth/adduserid`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        userid: userid,
        userName,
      }),
    });
    const json = await response.json();
    if (json.success) {
      setUser(json.user);
      getLinks();
      getSettings();
      props.showAlert(json.message, "success");
    } else {
      props.showAlert(json.message, "danger");
    }
  };

  const userDetails = async () => {
    const response = await fetch(`${host}/auth/getuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    });
    const json = await response.json();
    setUser(json.user);
  };

  const viewUserDetails = async (id) => {
    const response = await fetch(`${host}/auth/viewGetUser/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    setUser(json.user);
  };

  // Update themes
  const changeTheme = async (selectedTheme, userName) => {
    console.log("tt", selectedTheme, userName);
    const id = userInfo && userInfo._id;
    try {
      const response = await fetch(`${host}/settings/updateSettings`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ curTheme: selectedTheme, userName, id }),
      });
      const json = await response.json();
      await getSettings();
    } catch (error) {
      console.log(error);
    }
  };

  // Get share user data
  const fetchShareCbxtreeData = async () => {
    try {
      const response = await fetch(`${host}/settings/fetchShareTreeData`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      });
      if (!response.ok) {
        console.log("Something went wrong");
      }
      const data = await response.json();
      setShareSettingData(data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // Delete Settings Apis -
  const handleDeleteLogoUrl = async (aliasName) => {
    const shareUserId = userInfo && userInfo._id;
    try {
      const response = await fetch(`${host}/settings/delete-logo-url`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          shareUserId,
          aliasName,
        }),
      });
      let res = await response.json();
      getSettings();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteBackgroundImageUrl = async (aliasName) => {
    const shareUserId = userInfo && userInfo._id;
    try {
      const response = await fetch(`${host}/settings/delete-background-url`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          shareUserId,
          aliasName,
        }),
      });
      let res = await response.json();
      getSettings();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteBackground = async (aliasName) => {
    const shareUserId = userInfo && userInfo._id;
    try {
      const response = await fetch(`${host}/settings/delete-background`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          shareUserId,
          aliasName,
        }),
      });
      let res = await response.json();
      getSettings();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteFaviconIconAndTitle = async (aliasName) => {
    const shareUserId = userInfo && userInfo._id;
    try {
      const response = await fetch(
        `${host}/settings/delete-faviconiconandtitle`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            shareUserId,
            aliasName,
          }),
        }
      );
      let res = await response.json();
      getSettings();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteHeaderValue = async (aliasName, settingName) => {
    const shareUserId = userInfo && userInfo._id;
    try {
      const response = await fetch(`${host}/settings/delete-header`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          shareUserId,
          aliasName,
          settingName,
        }),
      });
      let res = await response.json();

      getSettings();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteDomainUrl = async (id) => {
    try {
      const response = await fetch(`${host}/auth/delete-domain/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      });
      let res = await response.json();
      userDetails();
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateQRCodeStatus = async (isCheck, userName) => {
    const id = userInfo && userInfo._id;
    try {
      const response = await fetch(`${host}/settings/isQRCode-check`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ isCheck, userName, id }),
      });

      if (response.ok) {
        console.log("Updated successfully");
        getSettings();
      } else {
        console.error("Failed to update", await response.text());
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  // New feature updates apis
  const handleAddFeatures = async (formData) => {
    try {
      const response = await fetch(`${host}/features/addFeature`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(formData),
      });
      setNewFeaturesData(response.data);
      getAllNewFeaturesData();
    } catch (error) {
      console.log(error);
    }
  };

  const getAllNewFeaturesData = async () => {
    try {
      const response = await fetch(`${host}/features/getAllFeatures`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      });
      const data = await response.json();

      if (data?.features) {
        setNewFeaturesData(data.features);
        const newFeatures = data.features;
        const newFeatureIds = newFeatures.map((feature) => feature._id);

        // Retrieve previously stored feature IDs
        const storedFeatureIds =
          JSON.parse(localStorage.getItem("featureIds")) || [];

        // Find unique new features
        const uniqueNewFeatures = newFeatureIds.filter(
          (id) => !storedFeatureIds.includes(id)
        );

        // Update badge count if there are new unique features
        const newBadgeCount = uniqueNewFeatures.length;
        if (newBadgeCount > 0) {
          setBadgeCount(newBadgeCount);
          localStorage.setItem("badgeCount", newBadgeCount);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetBadgeCount = () => {
    // Save current new features as seen
    const seenFeatureIds = newFeaturesData.map((feature) => feature._id);
    const storedFeatureIds =
      JSON.parse(localStorage.getItem("featureIds")) || [];

    // Store updated seen feature IDs in localStorage
    localStorage.setItem(
      "featureIds",
      JSON.stringify([...storedFeatureIds, ...seenFeatureIds])
    );

    // Reset the badge count to 0
    setBadgeCount(0);
    localStorage.setItem("badgeCount", 0);
  };

  const handleDeleteFeature = async (id) => {
    try {
      const response = await fetch(`${host}/features/delete-feature/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      });
      let res = await response.json();
      getAllNewFeaturesData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditFeature = async (id, formData) => {
    try {
      const response = await fetch(`${host}/features/update-feature/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(formData),
      });
      let res = await response.json();
      console.log("res", res);
      getAllNewFeaturesData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <linkContext.Provider
      value={{
        links,
        settings,
        previewUrl,
        viewlinks,
        viewsetting,
        clicks,
        user,
        fetchclicks,
        getLinks,
        updatelinkED,
        updateOrder,
        addLink,
        deleteLink,
        editLink,
        addAnimation,
        addEndDate,
        onChangethumbnail,
        handleSubmit,
        getSettings,
        updateLogo,
        updateBgImage,
        updateLgBackground,
        updateSocialLinks,
        deleteSL,
        addHeader,
        addSheetLink,
        addDescription,
        getUserid,
        loadLinks,
        loadSettings,
        updaterightfooter,
        updateleftfooter,
        handleFooter,
        handleSearch,
        addClickInfo,
        updateHeaderSetting,
        updateDescriptionSetting,
        updatedleftfooterStyle,
        updatedrightfooterStyle,
        updatedpaginationStyle,
        updatedLinksStyle,
        handleAddDomain,
        handleAddUseId,
        userDetails,
        changeTheme,
        setPreviewUrl,
        userViewDetails,
        viewUserDetails,
        addCompanyName,
        getAllLinks,
        getLinksCount,
        linkCount,
        allLinks,
        userLogs,
        getUserLogs,
        getLinksLogs,
        linksLogs,
        updateLinkSecureStatus,
        UserData,
        allUsers,
        isLoading,
        setIsLoading,
        handleDeleteLogoUrl,
        handleDeleteBackgroundImageUrl,
        handleDeleteBackground,
        handleDeleteFaviconIconAndTitle,
        handleDeleteHeaderValue,
        handleDeleteDomainUrl,
        handleUpdateQRCodeStatus,
        shareSettingData,
        fetchShareCbxtreeData,
        settingsCount,
        getSettingsCount,
        shouldHideIcons,
        setShouldHideIcons,
        handleAddFeatures,
        newFeaturesData,
        getAllNewFeaturesData,
        badgeCount,
        setBadgeCount,
        resetBadgeCount,
        handleDeleteFeature,
        handleEditFeature,
      }}
    >
      {props.children}
    </linkContext.Provider>
  );
};

export default LinkState;

// const [previewUrl, setPreviewUrl] = useState("");
// let previewUrl = "";

// if (user?.domain !== "") {
//   if (user?.userid?.length > 0) {
//     previewUrl = `${user?.domain}/${user?.userid[user?.userid.length - 1]}`;
//   } else {
//     previewUrl = `${user?.domain}/${user?._id}`;
//   }
// } else {
//   if (user?.userid?.length > 0) {
//     previewUrl = `${publicUrl}/${user?.userid[user?.userid.length - 1]}`;
//   } else {
//     previewUrl = `${publicUrl}/${user?._id}`;
//   }
// }

// Old
// if (user?.domain !== "") {
//   if (user?.userid !== "") {
//     previewUrl = `${user?.domain}/${user?.userid}`;
//   } else {
//     previewUrl = `${user?.domain}/${user?._id}`;
//   }
// } else {
//   if (user?.userid !== "") {
//     previewUrl = `${publicUrl}/${user?.userid}`;
//   } else {
//     previewUrl = `${publicUrl}/${user?._id}`;
//   }
// }
