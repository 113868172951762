import { React, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../HomePage/HomePage";
import View from "../View";
import Home from "../Home";
import Login from "../Auth/Login";
import Signup from "../Auth/Signup";
import SendOtp from "../Auth/SendOtp";
import Users from "../AdminDashboard/Users";
import Dashboard from "../UserLinksDashboard/Dashboard";
import CopySettings from "../CopySettings";
import Navbar from "../Navbar/Navbar";
import ShowNavbar from "../ShowNavbar";
import Alert from "../Alert";
import UsersDashboard from "../AdminDashboard/UsersDashboard";

const AllRoutes = () => {
  const [alert, setAlert] = useState(null);
  const [alertId, setAlertId] = useState(0);

  const showAlert = (message, type) => {
    setAlert({
      id: alertId,
      msg: message,
      type: type,
    });
    setAlertId((prevId) => prevId + 1);
    setTimeout(() => {
      setAlert(null);
    }, 1500);
  };

  const loadGoogleTranslateScript = () => {
    const script = document.createElement("script");
    script.src =
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        { pageLanguage: "en", autoDisplay: false },
        "google_translate_element"
      );
    };
  };

  useEffect(() => {
    loadGoogleTranslateScript();
  }, []);

  return (
    <div>
      <ShowNavbar>
        <Navbar />
        <Alert alert={alert} />
      </ShowNavbar>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:id" element={<View showAlert={showAlert} />} />
        <Route path="/link" element={<Home showAlert={showAlert} />} />
        <Route path="/login" element={<Login showAlert={showAlert} />} />
        <Route path="/signup" element={<Signup showAlert={showAlert} />} />
        <Route path="/sendotp" element={<SendOtp showAlert={showAlert} />} />
        <Route exact path="/" element={<Navigate to="/login" />} />
        {/* <Route path="/users" element={<Users showAlert={showAlert} />} /> */}
        <Route
          path="/users"
          element={<UsersDashboard showAlert={showAlert} />}
        />
        <Route
          path="/settings"
          element={<CopySettings showAlert={showAlert} />}
        />
        <Route
          path="/dashboard"
          element={<Dashboard showAlert={showAlert} />}
        />
      </Routes>
    </div>
  );
};

export default AllRoutes;
